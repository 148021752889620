import React, { Component } from 'react';
import './car.css';

export default class Car extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount(){
      console.log(88888888888);
  }

    
  render() {
    return (
        <div className="car">
              
        </div>
    );
  }
}
