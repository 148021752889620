import React, { Component } from 'react';
import './index.css';

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }



  componentDidMount(){
      
  }

    
  render() {
    return (
        <div className="index">
              
        </div>
    );
  }
}
