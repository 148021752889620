import React, { Component } from 'react';
import './smoke.css';

export default class Smoke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount(){
      
  }

    
  render() {
    return (
        <div className="smoke">
              
        </div>
    );
  }
}
