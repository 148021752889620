import React, { Component } from 'react';
import './Order.css';
import {GET,ApiList} from './utils/network';
import {WXSHARE} from './utils/wxtools';

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount(){
    this.getOrderList();
    this.wx_Share();

  }

  wx_Share=()=>{
    WXSHARE(
      {
        title:'订单',
        desc: '订单列表',
        link: window.location.href,
        imgUrl: 'http://mapp.alicdn.com/1618408849423qKFbqTtu7JXih2n.png',
      }
    );
  }

  getOrderList = async ()=>{
    let orderList = await GET(ApiList.GetOrderList);
    console.log(orderList);
  }

  render() {
    return (
        <div className="Order">
              
        </div>
    );
  }
}
