import React, { Component } from 'react';
import './App.css';
import {WXSHARE} from './utils/wxtools';
import {GET,ApiList} from './utils/network';
import TabBar from './Component/TabBar.js';
import Index from './pages/index.js';
import Cigarette from './pages/cigarette.js';
import Smoke from './pages/smoke.js';
import Car from './pages/car.js';


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIndex:0,
      goodsList:[],
      cigaretteList:[],
      smokeList:[]
    }
  }

  componentDidMount(){
    this.wx_Share();
    this.getGoodsList();
}

getGoodsList = async ()=>{
  let data = await GET(ApiList.GetGoodsList);
  let goodsList = data.data;
  this.setState(goodsList)
  let cigaretteList = goodsList.filter((item)=>{
    return item.tagIds.indexOf(2) !== -1;
  });
  this.setState(cigaretteList)

  let smokeList = goodsList.filter((item)=>{
    return item.tagIds.indexOf(3) !== -1;
  });
  this.setState(smokeList)
}



wx_Share=()=>{
  WXSHARE(
    {
      title:'YOOZ下单',
      desc: '开始下单吧',
      link: 'http://yooz.sangonsek.com',
      imgUrl: 'http://mapp.alicdn.com/1618408849423qKFbqTtu7JXih2n.png',
    }
  );
}

onTabChange=(index)=>{
  this.setState({selectIndex:index});
}

  render() {
    return (
      <div className="App">
        {[<Index goodsList={this.state.goodsList}/>,<Cigarette cigaretteList={this.state.cigaretteList}/>,<Smoke smokeList={this.state.smokeList}/>,<Car/>][this.state.selectIndex]}
        <TabBar onTabChange={this.onTabChange}/>
      </div>
    );
  }
}
