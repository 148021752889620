import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Order from './Order';
import reportWebVitals from './reportWebVitals';


function getQueryVariable (variable){
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] === variable){return pair[1];}
  }
  return(false);
}

ReactDOM.render(
  <React.StrictMode>
   {getQueryVariable('oid')?<Order oid={getQueryVariable('oid')}/>:<App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
